.root {
  background-color: #141414;

}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-color: #141414;
}

.projHeader {
  width: 700px;
  height: 300px;
  flex-direction: column;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 100px;
}

.projHeaderIntro {
  color: white;
  font-size: 80px;
  font-weight: 700;
}

.introParaProj {
  color: white;
  text-align: center;
  font-size: 30px;
}

.projectContainer {
    font-family: sans-serif;
      margin: 3px auto 150px;
      padding: 1.5em;
      width: 70em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 50px;
      column-gap: 50px;
}

figure {
  color: white;
  background-color: #141414;
  float: left;
  -webkit-transform: perspective(500);
  -webkit-transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  height: 300px;
  width: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

figure:hover {
  -webkit-transform: perspective(500) rotateX(100deg) translateY(-3em) translateZ(8em);
}

img {
  display: block;
  height: 300px;
  width: 295px;
  object-fit: cover;
  -webkit-transition: 0.5s;
  border: 2px solid white;
}

figure:hover img {
  box-shadow: none;
}

figcaption {
  background-color: #141414;
  border: solid 3px white;
  color: white;
  -webkit-transform: rotateX(-100deg);
  -webkit-transform-origin: 100% 0;
  -webkit-transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  gap: 20px;
}

figure:hover figcaption {
  box-shadow: 0 20px 15px -10px hsla(0, 0%, 0%, 0.25);
}

h3 {
  font-weight: bold;
  bottom: 20px;
}

.figdesc {
  font-size: 15px;
  bottom: 15px;
  position: relative;
}

.buttons {
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  width: 220px;
  bottom: 10px;
  position: relative;
}

.github,
.demo {
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  width: 100px;
}

.github:hover,
.demo:hover {
  background-color: white;
  border: 2px solid white;
  color: black;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  width: 100px;
}
//box projects
.box-container{
  z-index: 2;
  width: 300px;
  height: 300px;
  padding: 10px;
  border: solid 3px white;
  background-color:#141414;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
-webkit-box-shadow: 0px 0px 29px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 29px -8px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 29px -8px rgba(0, 0, 0, 1);
  transition: all 0.2s ease-in-out;
}

.box-container:hover{
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.project-title{
  font-size: 24px;
  color: white;
}
.project-desc {
  font-size: 16px;
  font-weight: 500;
  width: 85%;
  color: white;
  text-align: justify;
}
.projectContainer div:nth-child(1) .project-btn, .projectContainer div:nth-child(2) .project-btn{
  justify-content: space-between;
}

.project-btn {
    display: flex;
    width: 85%;
    flex-direction: row;
    justify-content: center;
}
.project-btn button {
  width: 100px;
  background-color: transparent;
  border: solid 2px white;
  color: white;
  cursor: pointer;
}

.project-btn button:hover {
  background-color: white;
  color: black;
}

/* Media Queries */

@media (max-width: 1200px) {
  .projHeader {
    width: 500px;
    height: auto;
    margin-top: 100px;
  }

  .projHeaderIntro {
    font-size: 60px;
  }

  .introParaProj {
    font-size: 20px;
  }

  .projectContainer {
    width: 100%;
    padding: 1em;
    grid-template-columns: 1fr;
  }

  figure {
    float: none;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .projHeader {
    width: 300px;
    height: auto;
    margin-top: 120px;
  }

  .projHeaderIntro {
    font-size: 35px;
    padding-bottom: 30px;
  }

  .introParaProj {
    font-size: 15px;
    padding-bottom: 30px;
  }

  .projectContainer {
    width: 100%;
    padding: 1em;
    grid-template-columns: 1fr;
  }

  figure {
    float: none;
    margin: 0 auto;
  }
}




// backgroun animations

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Exo', sans-serif;
}


.context {
  width: 100%;
  position: absolute;
  top: 50vh;

}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}



.circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 0;
  }

}