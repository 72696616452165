@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

$color-light-gray: #eaece5;

.root {
  background-color: #141414;
}

.body {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  align-items: center;
  margin-top: 150px;
  text-align: start;
}

.right {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 150px 0px 0px;
}

.pic {
  width: 400px;
  border-radius: 1000px;
}

.homeImage {
  width: 550px;
  height: 550px;
  border: none;
}

.header {
  color: white;
  font-size: 100px;
  text-align: start;
  width: 700px;
}

.p1 {
  color: white;
  font-size: 40px;
  text-align: start;
  width: 700px;
}

.textwrapper {
}

.waviy {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 700px;
}

.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
  animation: flip 3s infinite;
  animation-delay: calc(0.2s * var(--i));
}

@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.intro {
  font-size: 40px;
  text-align: start;
}

.text {
  font-size: 8em;
  position: relative;
  display: flex;
  z-index: 1;
}

.letter {
  position: relative;
  display: flex;
}

.source {
  color: gray;
  -webkit-text-stroke: 0.01em rgba(black, 0.3);
  display: flex;
}

.overlay,
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}

$color-light-gray: #cccccc;

.overlay {
  background-image: linear-gradient(90deg, white 50%, lighten($color-light-gray, 2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: rotateY(-30deg) skew(0, -10deg);
  transform-origin: left;
  animation: overlay 3s infinite ease-out var(--delay);
}

.shadow {
  filter: blur(5px);
  background-image: linear-gradient(90deg, rgba(black, 0.4) 30%, transparent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: skew(0, 20deg) translateY(0.1em) translateX(0.05em);
  animation: shadow 3s infinite var(--delay);
}

.text .letter {
  font-weight: 700;
  font-size: 120px;
  text-align: center;
}

@keyframes shadow {
  0%,
  20%,
  100% {
    transform: skew(0, 20deg) translateY(0.1em) translateX(0.05em);
    opacity: 1;
  }
  10% {
    transform: skew(0, 0) translateY(0) translateX(0);
    opacity: 0;
  }
}

@keyframes overlay {
  0%,
  20%,
  100% {
    transform: rotateY(-30deg) skew(0, -10deg);
  }
  10% {
    transform: rotateY(0deg) skew(0, 0);
  }
}

@keyframes typed {
  /* Keyframe animation code */
}

@keyframes beam-blink {
  75% {
    border-color: transparent;
  }
}

$strings: ('Aspiring Developer' 'Passionate Learner');

$durCharFwd: 0.1; // character typed
$durFullGap: 2; // time between typed/delete
$durCharBwd: 0.08; // character deleted
$durDoneGap: 1; // time between strings

$charCount: 0;
$durTotal: 0;

@each $string in $strings {
  $charCount: $charCount + str-length($string);
  $durTotal: $durTotal +
    (str-length($string) * ($durCharFwd + $durCharBwd)) +
    $durFullGap +
    $durDoneGap;
}

@function percent($string, $letter, $modifier) {
  $stringsPast: $string - 1;
  $time: 0;

  @while $stringsPast > 0 {
    $time: $time +
      (($durCharFwd + $durCharBwd) * (str-length(nth($strings, $stringsPast)))) +
      $durFullGap +
      $durDoneGap;
    $stringsPast: $stringsPast - 1;
  }

  @if $letter <= str-length(nth($strings, $string)) {
    $time: $time + ($durCharFwd * ($letter - 1));
  } @else {
    $time: $time +
      ($durCharFwd * str-length(nth($strings, $string))) +
      $durFullGap +
      ($durCharBwd * ($letter - str-length(nth($strings, $string))));
  }

  @return ($time / $durTotal * 100 + $modifier) + '%';
}

$currentPercentage: 0;

@keyframes typed {
  @for $i from 1 through length($strings) {
    @for $j from 1 through (str-length(nth($strings, $i)) * 2) {
      @if $j < str-length(nth($strings, $i)) * 2 {
        #{percent($i, $j, 0)},
        #{percent($i, $j+1, -0.001)} {
          @if $j <= str-length(nth($strings, $i)) {
            content: quote(#{str_slice(nth($strings, $i), 1, $j)});
          } @else {
            content: quote(
              #{str_slice(
                  nth($strings, $i),
                  1,
                  str-length(nth($strings, $i)) - ($j - str-length(nth($strings, $i)))
                )}
            );
          }
        }
      } @else {
        @if $i < length($strings) {
          #{percent($i, $j, 0)},
          #{percent($i+1, 1, -0.001)} {
            content: '​'; // zero-width space to retain element height
          }
        } @else {
          #{percent($i, $j, 0)},
          100% {
            content: '​'; // zero-width space to retain element height
          }
        }
      }
    }
  }
}

.typingtext::after {
  content: '';
  animation: typed 10s linear 2s infinite, beam-blink 2s infinite;
  color: white;
  text-align: start;
  width: 100%;
  white-space: nowrap;
  overflow: visible;
}

.brIntro {
}

.topdiv {
    display: flex;
    flex-direction: column;
    height: 800px;
    align-items: center;
}

.botdiv {
  height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: end;
}

.container {
  width: 1300px;
  background-color: rgba(255, 0, 0, 0);
  
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;
  align-items: center;
  row-gap: 30px;
  z-index: 999;
}

.introHead {
  font-weight: 700;
  font-size: 80px;
  color: white;
  text-align: start;
  align-items: center;
  display: flex;
  width: 80%;
}

.introP {
  color: white;
  text-align: justify;
  font-size: 30px;
  width: 80%;
}

.cvBtn {
  width: 200px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  top: 40px;
  
}

.cvBtn:hover {
  width: 200px;
  height: 50px;
  background-color: white;
  border: 2px solid white;
  color: black;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  top: 40px;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  bottom: 60px;
  
}

@keyframes arrow {
  100% {
    transform: translateY(10px);
  }
}

.arrow {
  display: inline-block;
  animation: arrow 0.7s ease-in alternate infinite;
}

/* Media Queries */

@media (max-width: 1200px) {

  .botdiv{
    margin: 0 30px 0 30px;
  }
  
  .topdiv {
    flex-direction: column;
    height: auto;
    padding: 20px;
    margin-top: 5%;
  }
  .typingtext::after{
    font-size: 30px;
  }
  .introHead {
    font-size: 40px;
  }

  .left,
  .right {
    width: 100%;
    align-items: center;
    margin-top: 20px;
    text-align: center;
  }

  .pic {
    width: 300px;
  }

  .homeImage {
    width: 400px;
    height: 400px;
  }

  .header {
    margin-top: 140px;
    font-size: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .p1 {
    font-size: 30px;
    width: 100%;
  }

  .waviy {
    width: 100%;
  }

  .waviy span {
    font-size: 30px;
  }

  .text .letter {
    font-size: 60px;
  }

  .text .letter .source {
    font-size: 80px;
  }
  .text .letter .shadow {
    font-size: 80px;
  }
  .text .letter .overlay {
    font-size: 80px;
  }

  .typingtext::after{
    font-size: 25px;
  }

  .intro {
    font-size: 20px;
  }

  .container {
    width: 100%;
    margin-bottom: 100px;
  }

  .introHead {
    font-size: 60px;
  }

  .introP {
    font-size: 20px;
  }

  .cvBtn {
    width: 150px;
    height: 40px;
    font-size: 12px;
    top: 20px;
  }

  .timeline {
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .topdiv {
    flex-direction: column;
    height: auto;
    padding: 20px;
    margin-top: 10%;
  }
}

@media screen and (max-width: 768px){
    .topdiv {
        flex-direction: column;
        height: auto;
        padding: 20px;
        margin-top: 10%;
      }
}

@media (max-width: 500px) {
  .header {
    margin-top: 120px;
    font-size: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .p1 {
    font-size: 20px;
    width: 100%;
  }

  .waviy span {
    font-size: 20px;
  }

  .text .letter {
    font-size: 40px;
  }

  .text .letter .source {
    font-size: 60px;
    z-index: 0;
  }
  .text .letter .shadow {
    font-size: 60px;
  }
  .text .letter .overlay {
    font-size: 60px;
  }

  .intro {
    font-size: 40px;
  }

  .introHead {
    font-size: 40px;
  }

  .introP {
    font-size: 15px;
  }

  .cvBtn {
    width: 120px;
    height: 30px;
    font-size: 10px;
    top: 10px;
  }

  .typingtext::after{
    font-size: 20px;
  }

  .homeImage {
    width: 300px;
    height: 300px;
  }
  .timeline h1{
    font-size: 2.2rem;
  }
  .botdiv{
    margin: 0 30px 0 30px;
  }
  .cvBtn{
    padding: 0;
  }
}


@media (max-width: 380px) {
  .header {
    margin-top: 80px;
    font-size: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .p1 {
    font-size: 20px;
    width: 100%;
  }

  .waviy span {
    font-size: 20px;
  }

  .text .letter {
    font-size: 40px;
  }

  .text .letter .source {
    font-size: 50px;
  }
  .text .letter .shadow {
    font-size: 50px;
  }
  .text .letter .overlay {
    font-size: 50px;
  }

  .intro {
    font-size: 30px;
  }

  .introHead {
    font-size: 30px;
  }

  .introP {
    font-size: 15px;
  }

  .cvBtn {
    width: 120px;
    height: 30px;
    font-size: 10px;
    top: 10px;
  }

  .typingtext::after{
    font-size: 20px;
  }

  .homeImage {
    width: 250px;
    height: 250px;
  }
  .timeline h1{
    font-size: 2rem;
  }
  .botdiv{
    margin: 0 30px 0 30px;
  }
  .cvBtn{
    padding: 0;
  }
}
// backgroun animations

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Exo', sans-serif;
}


.context {
  width: 100%;
  position: absolute;
  top: 50vh;

}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}



.circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;

}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 0;
  }

}