.root {
  background-color: #232323;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.menu {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 38px;
  margin: 50px;
}

.logoSpan {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 20px;
  width: auto;
  margin-left: 130px;
  cursor: pointer;
}

.menuItems {
  color: white;
  font-size: 15px;
  text-align: start;
  width: auto;
  cursor: pointer;
}

.navItems {
  list-style-type: none;
}

.hamburger{
  display: none;
  cursor: pointer;
  margin-right: 20px;
}

.bar{
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.menuItems {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  position: relative;
}

.menuItems:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.menuItems:hover:after {
  width: 100%;
  left: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    margin: 0;
  }

  .logoSpan {
    margin-left: 20px;
  }

  .hamburger{
    display: block;
  }

  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg)
  }

  .hamburger.active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg)
  }

  .menu {
    position: fixed;
    top: -110%;
    right: 0;
    gap: 0;
    background-color: #232323;
    width: 30%;
    text-align: center;
    transition: 0.3s;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .navItems{
    margin: 16px 0;

  }

  .menu.active{
    top: 60px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
  }

}
