.root {
  background-color: #141414;
}

.body {
  display: flex;
  flex-direction: row;
  height: 100vh;
  
}

.left {
  width: 50%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 150px 0 0 0;
  padding-right: 40px;
  height: auto;
}

.right {
  width: 50%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 20px;
  margin-left: 100px;
  padding-left: 40px;
  margin: 150px 0 0 0;
  height: auto;
}

.headerCont {
  color: white;
  text-align: end;
  font-size: 100px;
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
}

.ptag {
  color: white;
  text-align: end;
  font-size: 20px;
  font-weight: 400;
}

.name {
  width: 500px;
  height: 30px;
  margin-top: 40px;
  padding: 10px;
}

.email {
  height: 30px;
  width: 500px;
  padding: 10px;
}

.message {
  width: 500px;
  height: 300px;
  resize: none;
  padding: 10px;
}

.sendBtn {
  width: 100px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

.sendBtn:hover {
  width: 100px;
  height: 40px;
  background-color: white;
  border: 2px solid white;
  color: black;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

.icons {
  display: flex;
  flex-direction: row;
  width: 200px;
  height: 50px;
  position: relative;
  top: 25px;
}

.connect {
  color: white;
  font-size: 35px;
  position: relative;
  top: 20px;
}

.githubic,
.fbic,
.igic,
.linic {
  color: white;
  font-size: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.githubic:hover,
.fbic:hover,
.igic:hover,
.linic:hover {
  transform: scale(1.2);
}

.toastContainer {
  background-color: black;
}

.toast {
  border: 1px solid white;
  background-color: black;
}

.toastBody {
  color: white;
}

/* Responsive Styles */

@media (max-width: 1200px) {
  /* For screens smaller than 768px */
  .body {
    flex-direction: column;
    height: 100vh;
  }

  .left,
  .right {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px;
    align-items: center;
    
  }

  .left {
    top: 0;
    margin-top: 100px
  }

  .headerCont {
    align-items: center;
    text-align: center;
    font-size: 35px;
  }

  .ptag {
    margin-bottom: 20px;
    text-align: center;
  }

  .name,
  .email,
  .message {
    width: 100%;
  }

  .icons {
    justify-content: center;
    top: 0;
  }

  .connect {
    text-align: center;
    font-size: 25px;
    top: 0;
  }
  .right{
    padding: 0 100px 0 100px;
  }
}




@media (max-width: 600px) {
  /* For screens smaller than 768px */
  .body {
    flex-direction: column;
    height: auto;
  }

  .left,
  .right {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px;
    align-items: center;
    
  }

  .left {
    top: 0;
    margin-top: 100px
  }

  .headerCont {
    align-items: center;
    text-align: center;
    font-size: 35px;
  }


  .ptag {
    margin-bottom: 20px;
    text-align: center;
  }

  .name,
  .email,
  .message {
    width: 100%;
  }

  .icons {
    justify-content: center;
    top: 0;
  }

  .connect {
    text-align: center;
    font-size: 25px;
    top: 0;
  }
}


// backgroun animations

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Exo', sans-serif;
}


.context {
  width: 100%;
  position: absolute;
  top: 50vh;

}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}



.circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 0;
  }

}