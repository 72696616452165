.root {
  background-color: #141414;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  gap: 30px;
  margin-top: 150px;
}

.bottom {
  width: 100%;
}
.AboutHead {
  color: white;
  font-weight: 700;
  font-size: 80px;
  text-align: center;
  width: 700px;
}
.aboutP {
  color: white;
  text-align: justify;
  width: 700px;
  font-size: 20px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 700;
}

/*Intro Section*/
.intro {
  padding: 200px 0;
}

.original {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
}

button {
  padding: 10px;
}

a {
  color: #000;
  text-decoration: none;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

h1 {
  font-size: 3.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.arrow {
  display: inline-block;
  animation: arrow 0.7s ease-in alternate infinite;
}

/*Timeline SECTION*/
.timeline ul {
  padding: 50px 0;
  overflow-x: hidden;
}

.timeline li {
  position: relative;
  width: 6px;
  padding-top: 50px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9);
  list-style-type: none;
}

.timeline li::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
}

.timeline div {
  position: relative;
  width: 400px;
  padding: 20px;
  background-image: linear-gradient(to bottom, white, white);
}

.timeline li:nth-child(odd) div {
  left: 45px;
  background-image: linear-gradient(180deg, rgba(254, 105, 118, 0) 0%, rgba(243, 103, 119, 0) 100%);
  border: 4px solid white;
  color: white;
  text-align: justify;
}
.timeline li:nth-child(even) div {
  left: -439px;
  color: white;
  border: 4px solid white;
  background-image: linear-gradient(180deg, rgba(254, 105, 118, 0) 0%, rgba(243, 103, 119, 0) 100%);
  text-align: justify;
}
.timeline li.in-view::before {
  background: white;
}

.timeline li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}
.timeline time {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  text-align: center;
}

.timeline div::after {
  content: '';
  position: absolute;
  border-style: solid;
  bottom: 7px;
}

.timeline li:nth-child(odd) div::after {
  left: -18px;
  border-width: 8px 16px 8px 0;
  border-color: transparent white transparent transparent;
}

.timeline li:nth-child(even) div::after {
  right: -18px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent white;
}

/*ANIMATION EFFECTS*/
@keyframes arrow {
  100% {
    transform: translateY(10px);
  }
}

/*GOING RESPONSIVE*/

@media screen and (max-width: 1200px) {
  .top {
    width: 80%;
  }

  .AboutHead {
    font-size: 60px;
  }

  .aboutP {
    width: 100%;
    font-size: 15px;
  }

  .timeline div {
    width: 300px;
  }

  .timeline li:nth-child(even) div {
    left: -339px;
  }
}


@media screen and (max-width: 900px) {
  .top {
    width: 90%;
    margin-top: 130px;
  }

  .AboutHead {
    font-size: 40px;
  }

  .aboutP {
    width: 100%;
    font-size: 15px;
    padding: 0 40px 0 40px;
  }

  .timeline div {
    width: 250px;
  }

  .timeline li:nth-child(even) div {
    left: -289px;
  }
}

@media screen and (max-width: 600px) {
  .container h1{
    font-size: 30px;
  }

  .intro {
    padding: 100px 0;
  }

  .AboutHead {
    font-size: 30px;
    width: 100%;
  }

  .aboutP {
    width: 100%;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  .timeline ul li {
    margin: 0 20px;
    font-size: 14px;
  }

  .timeline li div {
    width: calc(100vw - 85px);
  }

  .timeline li:nth-child(even) div {
    left: 45px;
  }

  .timeline li:nth-child(even) div::after {
    right: calc(100vw - 71px);
    border-width: 8px 16px 8px 0;
    border-color: transparent white transparent transparent;
  }
}

// backgroun animations

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Exo', sans-serif;
}


.context {
  width: 100%;
  position: absolute;
  top: 50vh;

}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}



.circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 0;
  }

}